<template>
    <div class="rounded-lg">
        <!-- appVersionFlag Dialog Start -->
        <div v-show="appVersionFlag">
            <v-row justify="center">
                <v-dialog v-model="appVersionFlag" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">
                            <p> {{ $t("Warning_Message.AppUpdatePromptVideoCall") }} </p>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAppVersionFlag" style="background-color: #1467BF;">
                                {{ $t("Warning_Message.Update") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
  		</div>
        <!-- appVersionFlag Dialog End -->
        
        <div class="px-2">
            <div class="d-flex justify-space-between align-center pt-2">
                <div class="d-flex align-center">
                    <div>
                        <v-avatar size="40px">
                            <img src="https://s3iconimages.mymedicine.com.mm/default_dp.png"/>
                        </v-avatar>
                    </div>

                    <div class="pl-1" align="left">
                        <span class="subheading">{{ appointment_data.booked_for_name }}</span>
                    </div>
                </div>

                <div class="d-flex justify-center align-center">
                    <div v-if="appointment_data.isLinkActive">
                        <v-btn class="join-btn subheading" style="background-color: #27AE60; color: #FFFFFF;" @click="redirectToVideoCall(appointment_data._id)" ><img src="https://s3iconimages.mymedicine.com.mm/join_call.svg" style="padding-right:5px;">{{ $t("Customer.Home.Join") }}</v-btn>
                    </div>
                    <div v-else>
                        <v-btn depressed color="primary" class="subheading"  @click="redirectToViewAppointment(appointment_data._id)" style="background-color: #1467BF;">
                            {{ $t("Customer.Home.View") }}
                        </v-btn>
                    </div>
                </div>
            </div>
            
            <div class="py-2 d-flex align-center">
                <div style="width: 80%">
                    <div class="ml-1 d-flex justify-start align-center" style="width: 100%">
                        <img src="https://s3iconimages.mymedicine.com.mm/calendar.svg"/>
                        <div class="px-1">
                            <dateRenderer class="normaltext" style="weight: 80%; height: 100%;" :dateVal="appointment_data.date_of_appointment"> </dateRenderer>
                        </div>
                    </div>
                </div>
                <div style="width: 100%">
                    <div class="d-flex justify-end align-center" style="width: 100%">
                        <img style="width: 24px; height: 24px;" src="https://s3iconimages.mymedicine.com.mm/clock.svg"/>
                        <div class="px-1">
                            <timeRenderer class="normaltext" style="weight: 80% height: 100%;" :timeSlot="appointment_data.time_slot"></timeRenderer>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pa-1" style="width: 100%; background-color: #fecdbe; border-radius: 0 0 4px 4px;">
            <span class="normaltext">
                {{ $t("Customer.Home.You_can_join_the_call_10_min_before_the_scheduled_time") }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'appointmentComponent',
        components: {
            dateRenderer: () => import('../views/Pages/Customer/dateRender.vue'),
            timeRenderer: () => import('../views/Pages/Customer/TimeRender.vue')
        },
        props: {
            appointment_data: {
                type: Object,
                requied: true
            }
        },
        data() {
            return {
                appVersionFlag: false,
            }
        },
        methods: {
            redirectToVideoCall(appointmentId) {
                let userAgent = navigator.userAgent;
                if (userAgent.includes("kbzpay")) {
                    let slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/');
                    if (slicedData[slicedData.length-1] < "5.2.3") {
                        this.appVersionFlag = true;
                    }
                }

                if (!this.appVersionFlag) {
                    this.$router.push({
                        path: '/customer/videocallTwilio/' + appointmentId
                    });
                }
            },
            redirectToViewAppointment(appointmentId) {
                this.$router.push({
                    path: '/customer/viewAppointment/' + appointmentId
                });
            },
            closeAppVersionFlag() {
                this.appVersionFlag = false;
                kbzpay.gotoFunction('native://kbz/customer/about');
            },
        }
    }
</script>

<style scoped>
.join-btn .v-btn__content {
    align-items: center;
    /* padding: 8px 24px; */
    background: #27AE60;
    color:#FFFFFF;
    border-radius: 8px;
    padding-right:10px;
}
</style>
